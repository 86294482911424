import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import GenerateContent from './components/GenerateContent';
import ReviewContent from './components/ReviewContent';

function HomePage() {
  const [step, setStep] = useState(1);

  function handleBack() {
    setStep(1);
  }

  function handleNext() {
    setStep(2);
  }

  return (
    <Box>
      {step === 1 && <GenerateContent />}
      {step === 2 && <ReviewContent />}
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        {step === 2 && (
          <Button
            variant="outlined"
            sx={{
              minWidth: 150,
              mr: 1,
            }}
            onClick={handleBack}
          >
            Back
          </Button>
        )}
        {/* <Button
            variant='outlined'
            sx={{
              minWidth: 150
            }}
            onClick={handleNext}
            disableRipple={step === 1}
          >
            {step === 2 ? 'Publish' : 'Next'}
          </Button> */}
      </Box>
    </Box>
  );
}

export default HomePage;
