import React from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import navMenu from '../../constants/NavMenu';
import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

function SideMenu() {
    const location= useLocation();
  return (
    <Drawer
        variant="permanent"
        sx={{
            width: drawerWidth,
            flexShrink: 0,
        }}
        PaperProps={{
            sx: { width: drawerWidth, overflow: 'auto' }
        }}
    >
        <Toolbar />
        <Box sx={{ pt: 2}}>
            <Typography variant='h3' mb={2}>Content</Typography>
            <List sx={{ mx: -3 }}>
                {navMenu.map(({icon: Icon, ...item}) => (
                    <ListItem
                        key={item.id}
                        disablePadding
                        sx={{
                            borderRight: '4px solid',
                            borderColor: location.pathname === item.path ? 'primary.main' : 'transparent'
                        }}
                    >
                        <ListItemButton href={item.path} sx={{ px: 3 }}>
                            <ListItemIcon>
                                <Icon />
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
                
            </List>
            <Button
                color='error'
                fullWidth
                sx={{
                    fontSize: '18px',
                    fontWeight: 600,
                    justifyContent: 'flex-start',
                    mt: 1,
                    px: 1.5
                }}
            >
                LOGOUT
            </Button>
        </Box>
    </Drawer>
  )
}

export default SideMenu