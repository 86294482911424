import React from 'react';
import Box from '@mui/material/Box';
import { Typography, Grid, Modal } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import { useGetArticlesQuery } from '../../services/articles';
import SelectDomain from '../../components/Common/SelectDomain';
import ReactMarkdown from 'react-markdown';

const ArticlesPage = () => {
  const [selectedDomain, setSelectedDomain] = React.useState('');
  const [action, setAction] = React.useState({});

  const { data: articles } = useGetArticlesQuery({ domain: selectedDomain }, { skip: !selectedDomain });

  return (
    <Box>
      <Typography variant="h4" component="h1" textAlign="center" mb={5}>
        Articles
      </Typography>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 3, md: 10, lg: 20 }}>
        <Grid item xs={6}>
          <SelectDomain showError={false} value={selectedDomain} onChange={(e) => setSelectedDomain(e.target.value)} />
        </Grid>
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>Title</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Slug</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Categories</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Internal Links</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>External Links</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Published (UTC)</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {articles &&
            articles.results.map((article) => (
              <TableRow key={article.id}>
                <TableCell>{article.title || article.keyword}</TableCell>
                <TableCell>{article.slug}</TableCell>
                <TableCell>{article.categories.join(', ')}</TableCell>
                <TableCell>{article.internalLinks || 0}</TableCell>
                <TableCell>{article.externalLinks || 0}</TableCell>
                <TableCell>{article.createdAt}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1.5 }}>
                    <Button
                      variant="outlined"
                      sx={{ borderRadius: 10, minWidth: 110 }}
                      size="small"
                      onClick={() => setAction({ content: article, type: 'edit' })}
                    >
                      View
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ borderRadius: 10, minWidth: 110 }}
                      size="small"
                      color="error"
                      onClick={() => alert('Not implemented yet!')}
                    >
                      Delete
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <Modal open={action.type === 'edit'} onClose={() => setAction({})}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 6,
            overflow: 'auto',
            maxHeight: '600px',
          }}
        >
          <Typography variant="h4" component="h2">
            {action.content?.keyword}
          </Typography>
          <br />
          <Typography variant="h5" component="h2">
            Categories: {action.content?.categories.join(', ')}
          </Typography>
          <Typography variant="h6" component="h2">
            Sub Categories: {action.content?.subCategories.join(', ')}
          </Typography>
          <Typography sx={{ mt: 2 }} component="div">
            <br />
            <Typography variant="h1" component="h1">
              Table of content (Manually added heading in code):
            </Typography>
            <br />
            <ReactMarkdown>{action.content?.tableOfContents}</ReactMarkdown>

            <br />
            <Typography variant="h1" component="h1">
              Article (Manually added heading in code):
            </Typography>
            <br />
            <img width="600" height="400" src={action.content?.featureImage} alt="" />
            <br />
            <ReactMarkdown>{action.content?.article}</ReactMarkdown>

            <ReactMarkdown>{action.content?.faqs}</ReactMarkdown>
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default ArticlesPage;
