import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useGetCategoriesQuery } from '../../../../services/categories';
import {
  useQueryChatGptMutation,
  makeChatGptCategoriesQuery,
  makeChatGptArticlesQuery,
  makeChatGptFaqQuery,
  // makeChatGptTableOfContentsQuery,
  makeChatGptJsonLdFaqQuery,
  pickBestFitLinks,
  slugify,
  getImageQuery
} from '../../../../services/openai';
import {
  useAddArticleMutation,
  useGetArticlesQuery,
  useUpdateArticlesMutation,
  useGetArticleSlugsByDomainQuery,
  useUpdateArticlesExternalLinksMutation,
} from '../../../../services/articles';
import { useGetPhotosMutation } from '../../../../services/pexelApi';
import { useGetDomainsQuery } from '../../../../services/domains';
import CircularProgress from '@mui/material/CircularProgress';
import SelectDomain from '../../../../components/Common/SelectDomain';
import { useNavigate } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';

const GenerateContent = () => {
  const navigate = useNavigate();

  const [inputs, setInputs] = React.useState({ keywords: '', domain: '' });
  const [mCategories, setMCategories] = React.useState([]); // generated categories
  const [articles, setArticles] = React.useState([]); // generated articles
  const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);

  const { data: domains } = useGetDomainsQuery();
  const [queryChatGpt, { isLoading }] = useQueryChatGptMutation();
  const [updateArticles, { isUpdating }] = useUpdateArticlesMutation();
  const [updateArticlesExternalLinks] = useUpdateArticlesExternalLinksMutation();
  const [getFeatureImage] = useGetPhotosMutation();
  const [addArticle, { isAddingArticle }] = useAddArticleMutation();
  const { data: categories } = useGetCategoriesQuery({ domain: inputs.domain }, { skip: !inputs.domain });
  const { data: articlesWithLowestLinks } = useGetArticlesQuery(
    { domain: inputs.domain, limit: 50, sortBy: 'internalLinks' },
    { skip: !inputs.domain }
  );
  const { data: slugsByEachDomain } = useGetArticleSlugsByDomainQuery(
    { domain: inputs.domain, limit: 10 },
    { skip: !inputs.domain }
  );

  const handleCategoriesMapping = async () => {
    if (!inputs.keywords) {
      alert('There must be atleast one keyword to map categories.');
      return;
    }
    if (!categories?.results.length) {
      alert('No category is available for mapping, please create some categories first.');
      return;
    }

    setMCategories([]);
    try {
      const response = await queryChatGpt({ query: makeChatGptCategoriesQuery(inputs.keywords, categories?.results) });
      try {
        const categories = JSON.parse(response.data?.message?.content);
        setMCategories(categories);
        return categories;
      } catch (e) {
        alert(response.data?.message?.content);
      }
    } catch (e) {
      alert('Error while generating categories, please try again later!');
      console.error(e);
    }
    return [];
  };

  const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  

  const generateArticle = async () => {
    if (!inputs.keywords) {
      alert('There must be atleast one keyword to generate article.');
      return;
    }
    if (!inputs.domain) {
      alert('Please select domain to proceed next.');
      return;
    }
    let generatedCats = mCategories;
    if (!mCategories.length) generatedCats = await handleCategoriesMapping();

    setArticles([]);
    const keywords = inputs.keywords.split(',');

    keywords.forEach(async (keyword, i) => {
      try {
        const findCategory = generatedCats.find((item) => item.keyword === keyword);
        if (!findCategory?.category) {
          return;
        }

        let bestFitInternalLinks = '';
        let bestFitExternalLinks = '';
        let featureImageUrl = '';
        const domainName = domains?.results.find((d) => d.id === inputs.domain)?.name;
        const baseUrl = domainName.startsWith('http') ? domainName : `https://${domainName}`;
        const links = articlesWithLowestLinks.results.filter(({ slug }) => !!slug);
        let slugs;
        if (links.length > 0) {
          slugs = JSON.stringify(links.map(({ slug }) => baseUrl + '/articles/' + slug));

          if (slugs) {
            const bestFitLinksResponse = await queryChatGpt({ query: pickBestFitLinks('internal', keyword, slugs) });
            bestFitInternalLinks = bestFitLinksResponse.data?.message?.content;
          }
        }

        if (slugsByEachDomain.length > 0) {
          const externalSlugs = JSON.stringify(slugsByEachDomain[0].map((slug) => slug.startsWith('http') ? slug : `https://${slug}`));
          const bestFitExternalLinksResponse = await queryChatGpt({
            query: pickBestFitLinks('external', keyword, externalSlugs),
          });
          bestFitExternalLinks = bestFitExternalLinksResponse.data?.message?.content;
        }

        const keywordForImageQuery = await queryChatGpt({
          query: getImageQuery(keyword),
        });

        const featureImageResponse = await getFeatureImage(keywordForImageQuery.data?.message?.content || '').unwrap();
        if (featureImageResponse?.photos?.length > 0) featureImageUrl = featureImageResponse.photos[randomIntFromInterval(0, featureImageResponse?.photos?.length-1)].src.original; // Do something with the feature image URL

        const response = await queryChatGpt({
          query: makeChatGptArticlesQuery(keyword, bestFitInternalLinks, bestFitExternalLinks),
        });
        const faqs = await queryChatGpt({ query: makeChatGptFaqQuery(keyword) });
        const jsonldFaqs = await queryChatGpt({ query: makeChatGptJsonLdFaqQuery(faqs.data?.message?.content) });

        const article = response.data?.message?.content;
        let title = article.split('\n')[0].replace('#', '');
        if (title.includes('#')) title = title.replace('#', '');

        const tableOfContents = article.split('\n').filter((line) => line.startsWith('##') && !line.startsWith('###') && !line.includes(title));

        // we will be removing the first heading and paragraph
        // from article and save it as separate heading
        const index = article.search(/\n\n## /);
        let introduction = article.slice(0, index);
        introduction = introduction.replace(/^# .*\n\n/, '');
        if (introduction.includes(title)) introduction = introduction.replace(title, '');
        if (introduction.includes('##')) introduction = introduction.replace('##', '').trim()
        ;
        let articleData = article.slice(index + 1);

        const headings = articleData.match(/^## .*/gm);
        let conclusion = '';
        if (headings) {
          const lastHeading = headings[headings.length - 1];
          const lastHeadingIndex = articleData.lastIndexOf(lastHeading);
          conclusion = articleData.slice(lastHeadingIndex);
          articleData = articleData.replace(conclusion, '');
        }

        try {
          setArticles([...articles, articleData]);
          await addArticle({
            domain: inputs.domain,
            title,
            tableOfContents: tableOfContents.join('\n'),
            slug: slugify(title),
            featureImage: featureImageUrl,
            internalLinks: 0,
            externalLinks: 0,
            keyword,
            article: articleData,
            faqs: faqs.data?.message?.content,
            jsonldFaqs: jsonldFaqs.data?.message?.content,
            categories: [findCategory?.category || ''],
            subCategories: findCategory?.subCategories || [''],
            introduction,
            conclusion
          });

          if (bestFitInternalLinks) await updateArticles({ slugs: JSON.parse(bestFitInternalLinks), domain: inputs.domain });
          if (bestFitExternalLinks)
            await updateArticlesExternalLinks({ slugs: JSON.parse(bestFitExternalLinks), domain: inputs.domain });
        } catch (e) {
          alert(article);
        }

        if (i + 1 === keywords.length) navigate('/articles');
      } catch (e) {
        alert('Error while generating articles, please try again later!');
        console.error(e);
      }
    });
  };

  const handleConfirmationResponse = async (proceed) => {
    setIsConfirmationOpen(false);
    if (proceed) {
      // Proceed with generating articles
      await generateArticle();
    }
  };

  const processGenerateArticle = async () => {
    if (!inputs.keywords) {
      alert('There must be atleast one keyword to generate article.');
      return;
    }
    if (!inputs.domain) {
      alert('Please select domain to proceed next.');
      return;
    }

    let generatedCats = mCategories;
    if (!mCategories.length) generatedCats = await handleCategoriesMapping();

    const keywords = inputs.keywords.split(',');

    let keywordWithCategory = 0;
    keywords.forEach(async (keyword, i) => {
      const findCategory = generatedCats.find((item) => item.keyword === keyword.trim());
      
      if (!findCategory?.category) {
        return;
      }
      keywordWithCategory++;
    });

    // if no keyword found that have category, article won't be
    // generated
    if (keywordWithCategory === 0) {
      alert('No keyword with category found.');
      return;
    } else if (keywordWithCategory === keywords.length) {
      // if all keywords have category then generate articles
      await generateArticle();
      return;
    }

    // some keyword have category and some don't
    setIsConfirmationOpen(true);
    return;
  }

  const updatingKeyword = (keyword) => {
    setMCategories([]);
    setInputs({ ...inputs, keywords: keyword });
  }

  return (
    <React.Fragment>
      <Typography variant="h4" component="h1" textAlign="center" mb={5}>
        Generate content
      </Typography>

      <Grid container rowSpacing={5} columnSpacing={{ xs: 3, md: 10, lg: 20 }} pb={2}>
        {/**   <Grid item xs={6}>
          <InputLabel sx={{ mb: 1.5, color: 'text.primary' }}>
            Category
          </InputLabel>
          <TextField select fullWidth>
            <MenuItem value="0">Select an option</MenuItem>
            <MenuItem value="1">Mental health</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <InputLabel sx={{ mb: 1.5, color: 'text.primary' }}>
            Subcategory
          </InputLabel>
          <TextField select fullWidth>
            <MenuItem value="0">Select an option</MenuItem>
            <MenuItem value="1">ADHD</MenuItem>
          </TextField>
        </Grid>  */}

        <Grid item xs={6}>
          <InputLabel sx={{ mb: 1.5, color: 'text.primary' }}>Domain</InputLabel>
          <SelectDomain
            helperText="Select domain for mapping keyword"
            value={inputs.domain}
            onChange={(e) => setInputs({ ...inputs, domain: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}></Grid>

        <Grid item xs={6}>
          <InputLabel sx={{ mb: 1.5, color: 'text.primary' }}>Keywords</InputLabel>
          <TextField
            fullWidth
            placeholder="what is ADHD?"
            helperText="One article will be generated for each keyword"
            onChange={(e) => updatingKeyword(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel sx={{ mb: 2.2, height: 23 }} />
          <Button
            disabled={!inputs.domain || isAddingArticle || isLoading}
            variant="outlined"
            size="large"
            fullWidth
            onClick={handleCategoriesMapping}
          >
            Map keywords
          </Button>
        </Grid>
      </Grid>

      {(isLoading || isAddingArticle || isUpdating) && <CircularProgress />}

      {mCategories.length > 0 && (
        <Grid item xs={12}>
          {mCategories.map((item) => (
            <Box key={item.keyword} sx={{ mb: 2 }}>
              <Typography variant="h4">{item.keyword}</Typography>
              <Typography variant="h5">Category: {item.category}</Typography>
              <Typography variant="h6">Sub Categories: {item.subCategories?.join(', ')}</Typography>
            </Box>
          ))}
        </Grid>
      )}

      <Grid container rowSpacing={5} columnSpacing={{ xs: 3, md: 10, lg: 20 }} pb={2}>
        {/** <Grid item xs={6}>
          <InputLabel sx={{ mb: 1.5, color: 'text.primary' }}>Posting frequency</InputLabel>
          <TextField select fullWidth helperText="Time between each article is published">
            <MenuItem value="0">Select an option</MenuItem>
            <MenuItem value="1">Every 8 hours</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <InputLabel sx={{ mb: 1.5, color: 'text.primary' }}>Internal links per article</InputLabel>
          <TextField fullWidth helperText="How many links in each article?" />
        </Grid>*/}
        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
          <Button
            onClick={processGenerateArticle}
            variant="outlined"
            sx={{ minWidth: 150 }}
            disabled={isLoading || isAddingArticle}
          >
            Generate Article(s)
          </Button>
        </Grid>

        {/** <Grid item xs={11}>
          {articles.map((article, i) => (
            <>
              <h1>Article #: {i + 1}</h1>
              <ReactMarkdown>{article}</ReactMarkdown>
            </>
          ))}
        </Grid>*/}
      </Grid>

      <Dialog open={isConfirmationOpen} onClose={() => handleConfirmationResponse(false)}>
        <Box p={2}>
          <Typography variant="h6">Some keywords have no category.</Typography>
          <Typography variant="body1">
            If you click proceed anyway, articles for keywords with categories will only be generated.
          </Typography>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={() => handleConfirmationResponse(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleConfirmationResponse(true)} color="primary">
              Proceed
            </Button>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default GenerateContent;
