const paths = {
  home: '/',
  articles: '/articles',
  categories: '/categories',
  addCategory: '/addCategory',
  schedule: '/schedule',
  settings: '/settings',
  domains: '/domains',
  addDomain: '/addDomain',
};

export default paths;
