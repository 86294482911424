import paths from './paths'
import Home from '@mui/icons-material/Home'
import Article from '@mui/icons-material/Article'
import Category from '@mui/icons-material/Category'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import Settings from '@mui/icons-material/Settings'
import Web from '@mui/icons-material/Web'

const navMenu = [
  { id: 1, name: 'Generate', path: paths.home, icon: Home },
  { id: 2, name: 'Domains', path: paths.domains, icon: Web },
  { id: 3, name: 'Articles', path: paths.articles, icon: Article },
  { id: 4, name: 'Categories', path: paths.categories, icon: Category },
  { id: 5, name: 'Schedule', path: paths.schedule, icon: CalendarMonth },
  { id: 6, name: 'Settings', path: paths.settings, icon: Settings },
]

export default navMenu
