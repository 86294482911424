import React from 'react';
import CustomThemeProvider from './theme';
import { Route, Routes } from 'react-router-dom';
import paths from './constants/paths';
import MainLayout from './layouts/MainLayout';
import HomePage from './pages/Home';
import ArticlesPage from './pages/Articles';
import DomainsPage from './pages/Domains';
import AddDomainPage from './pages/Domains/AddDomain';
import CategoriesPage from './pages/Categories';
import AddCategoryPage from './pages/Categories/AddCategory';

function App() {
  return (
    <CustomThemeProvider>
      <MainLayout>
        <Routes>
          <Route path={paths.home} element={<HomePage />} />
          <Route path={paths.articles} element={<ArticlesPage />} />
          <Route path={paths.domains} element={<DomainsPage />} />
          <Route path={paths.addDomain} element={<AddDomainPage />} />
          <Route path={paths.categories} element={<CategoriesPage />} />
          <Route path={paths.addCategory} element={<AddCategoryPage />} />
        </Routes>
      </MainLayout>
    </CustomThemeProvider>
  );
}

export default App;
