import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Grid, TextField } from '@mui/material';
import { useAddCategoryMutation } from '../../services/categories';
import { useNavigate } from 'react-router-dom';
import paths from '../../constants/paths';
import SelectDomain from '../../components/Common/SelectDomain';

const AddCategoryPage = () => {
  const navigate = useNavigate();
  const [addCategory, { isLoading }] = useAddCategoryMutation();
  const [inputs, setInputs] = React.useState({
    name: '',
    subCategories: '',
    domain: '',
  });

  const handleAddCategory = () => {
    if (!inputs.name) return;

    addCategory({ ...inputs, subCategories: inputs.subCategories.split(',') })
      .unwrap()
      .then(() => navigate(paths.categories))
      .catch((e) => alert(e?.data.message || e.message));
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" textAlign="center" mb={5}>
        Add Category
      </Typography>

      <Grid
        container
        rowSpacing={5}
        columnSpacing={{ xs: 3, md: 10, lg: 20 }}
        pb={2}
      >
        <Grid item xs={6}>
          <SelectDomain
            value={inputs.domain}
            onChange={(e) => setInputs({ ...inputs, domain: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            placeholder="Category name"
            helperText="* Enter category name."
            error={!inputs.name}
            onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="Comma separated sub categories"
            helperText="Enter comma separated sub categories like cat1,cat2,cat3."
            error={!inputs.subCategories}
            onChange={(e) =>
              setInputs({ ...inputs, subCategories: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            onClick={handleAddCategory}
            disabled={isLoading}
          >
            Add Category
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddCategoryPage;
