import { api } from './api';

export const categoriesApis = api.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (body) => ({
        url: `categories?domain=${body.domain}&&limit=100`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Categories', id: 'LIST' }],
    }),
    addCategory: builder.mutation({
      query: (body) => ({
        url: 'categories',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Categories', id: 'LIST' }],
    }),
  }),
});

export const { useGetCategoriesQuery, useAddCategoryMutation } = categoriesApis;
