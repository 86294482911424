import React from 'react';
import Box from '@mui/material/Box';
import paths from '../../constants/paths';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Button, Grid } from '@mui/material/';
import { useNavigate } from 'react-router-dom';
import { useGetCategoriesQuery } from '../../services/categories';
import SelectDomain from '../../components/Common/SelectDomain';

const CategoriesPage = () => {
  const navigate = useNavigate();
  const [selectedDomain, setSelectedDomain] = React.useState('');

  const { data } = useGetCategoriesQuery({ domain: selectedDomain }, { skip: !selectedDomain });

  return (
    <Box>
      <Typography variant="h4" component="h1" textAlign="center" mb={1}>
        Categories
      </Typography>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 3, md: 10, lg: 20 }}>
        <Grid item xs={6}>
          <SelectDomain showError={false} value={selectedDomain} onChange={(e) => setSelectedDomain(e.target.value)} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4" component="h5" textAlign="right">
            <Button variant="outlined" onClick={() => navigate(paths.addCategory)}>
              Add +
            </Button>
          </Typography>
        </Grid>
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Sub Categories</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.results.map((category) => (
              <TableRow key={category.name}>
                <TableCell>{category.name}</TableCell>
                <TableCell>{category.subCategories.join(',')}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default CategoriesPage;
