import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
// import IconButton from '@mui/material/IconButton';
// import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

const ReviewContent = () => {
    return (
        <Box mb={5}>
            <Typography variant='h4' component='h1' textAlign='center' mb={5}>Review content</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox />
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 700
                            }}
                        >
                            Title
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 700
                            }}
                        >
                            Keyword
                        </TableCell>
                        <TableCell
                            sx={{
                                fontWeight: 700
                            }}
                        >
                            To be published (UTC)
                        </TableCell>
                        {/* <TableCell /> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Checkbox />
                        </TableCell>
                        <TableCell>
                            How to treat ADHD?
                        </TableCell>
                        <TableCell>
                            How to solve ADHD?
                        </TableCell>
                        <TableCell>
                            2023-04-17 12:00
                        </TableCell>
                        {/* <TableCell>
                            <IconButton
                                sx={{ color: 'text.primary', p: 0 }}
                            >
                                <ArrowDropDown fontSize='large' />
                            </IconButton>
                        </TableCell> */}
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Checkbox />
                        </TableCell>
                        <TableCell>
                            5 signs of ADHD
                        </TableCell>
                        <TableCell>
                            signs of ADHD
                        </TableCell>
                        <TableCell>
                            2023-04-17 20:00
                        </TableCell>
                        {/* <TableCell>
                            <IconButton
                                sx={{ color: 'text.primary', p: 0 }}
                            >
                                <ArrowDropDown fontSize='large' />
                            </IconButton>
                        </TableCell> */}
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Checkbox />
                        </TableCell>
                        <TableCell>
                            Understand ADHD in 5 steps
                        </TableCell>
                        <TableCell>
                            What is ADHD?
                        </TableCell>
                        <TableCell>
                            2023-04-18 4:00
                        </TableCell>
                        {/* <TableCell>
                            <IconButton
                                sx={{ color: 'text.primary', p: 0 }}
                            >
                                <ArrowDropDown fontSize='large' />
                            </IconButton>
                        </TableCell> */}
                    </TableRow>
                    {/* <TableRow>
                        <TableCell colSpan={5} sx={{ border: 0, pt: 0 }}>
                            <Box
                                sx={{ border: '5px solid #d1d1d1', borderRadius: 2.5, textAlign: 'center', px: 3, py: 5 }}
                            >
                                <Typography variant='h1' component='h3' mb={4}>Understand ADHD in 5 steps</Typography>
                                <Typography variant='body1'>This is a markup preview of the generated article. Not required to be coded now</Typography>
                            </Box>
                        </TableCell>
                    </TableRow> */}
                </TableBody>
            </Table>
        </Box>
    )
}

export default ReviewContent

