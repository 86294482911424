import { api } from './api';

export const articlesApis = api.injectEndpoints({
  endpoints: (builder) => ({
    getArticles: builder.query({
      query: (body) => ({
        url: `articles?domain=${body.domain}&limit=${body.limit || 100}${body.sortBy ? '&sortBy=' + body.sortBy : ''}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Articles', id: 'LIST' }],
    }),
    addArticle: builder.mutation({
      query: (body) => ({
        url: 'articles',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Articles', id: 'LIST' }],
    }),
    updateArticles: builder.mutation({
      query: (body) => ({
        url: 'articles',
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => [{ type: 'Articles', id: 'LIST' }],
    }),
    getArticleSlugsByDomain: builder.query({
      query: (body) => ({
        url: `articles/slugs-by-domain?domain=${body.domain}&limit=${body.limit || 10}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Articles', id: 'LIST' }],
    }),
    updateArticlesExternalLinks: builder.mutation({
      query: (body) => ({
        url: 'articles/update-external-links',
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => [{ type: 'Articles', id: 'LIST' }],
    }),
  }),
});

export const { useGetArticlesQuery, useAddArticleMutation, useUpdateArticlesMutation, useGetArticleSlugsByDomainQuery, useUpdateArticlesExternalLinksMutation } = articlesApis;
