import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
// import { getAccessToken } from 'utils/token'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers) => {
    // const token = getAccessToken()
    // if (token) {
    //   headers.set('Authorization', `Bearer ${token}`)
    // }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const api = createApi({
  baseQuery: baseQueryWithRetry,
  reducerPath: 'apis',
  tagTypes: [],
  endpoints: () => ({}),
});

export const pexelApis = createApi({
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_PEXEL_API_URL,
      headers: {
        Authorization: process.env.REACT_APP_PEXEL_API_TOKEN,
      },
    }),
    { maxRetries: 0 }
  ),
  reducerPath: 'pexelApis',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  tagTypes: [],
  endpoints: () => ({}),
});
