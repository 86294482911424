import { api } from './api';

export const openAiApis = api.injectEndpoints({
  endpoints: (builder) => ({
    queryChatGpt: builder.mutation({
      query: (body) => ({
        url: 'openai',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const makeChatGptCategoriesQuery = (keywords, categories) => {
  const keywordsList = keywords.split(',').map((k) => k.trim());
  const sampleJson = keywordsList.map((kl) => ({
    keyword: kl,
    category: 'NAME OF BEST FIT CATEGORY SHOULD BE HERE.',
    subCategories: ['BEST FIT SUB CATEGORY 1', 'BEST FIT SUB CATEGORY 2', 'BEST FIT SUB CATEGORY 3'],
  }));

  return `Available/Existing Categories:
${JSON.stringify(categories)}

We are writing articles for below keywords array:
${JSON.stringify(keywordsList)}

Map each keyword to only one closest category (from list of available categories), max 3 closest subcategories from below list and return in purely below json format:
${JSON.stringify(sampleJson)}
Don't include any explaination, only expecting the pure json.
Always select one category and 1 or more subcategories.
Remove json text.`;
};

export const pickBestFitLinks = (type, keyword, links) => {
  return `Which links would you like to include in to an article '${keyword}'.
Here is the list of ${type} links, please pick maximum 5 links from below list:
${links}
Return the response in array without any extra word.
Always return at least five links and never return empty array, even if no links fit.
Remove json text.`;
};

export const getImageQuery = (keyword) => {
  return `Provide 1 most important word from this -  '${keyword}'. Return only the word, don't include any comments.`
};

const getLinksInstruction = (type = 'internal') => {
  return `Include following ${type} ahref links in the article so that they would seem like natural part of the article. Add only links from provided list. No other or random links can be added. Use as many links posts as possible.`;
};

export const makeChatGptArticlesQuery = (keyword, internalLinks, externalLinks) => {
  return `Compose a 2000-word, entirely original, SEO-optimized, and human-written article for a keyword "${keyword}" 
  that includes at least 5 headings and subheadings addressing the specified keyword in the prompt. 
  You should write in Markdown formatting.
  Write the article in your own words without copying from other sources. 
  Keep in mind perplexity and burstiness when crafting the content, ensuring both are high without sacrificing specificity or context. Create engaging and detailed paragraphs.
  Adopt a conversational writing style as if it were penned by a human (use informal language, personal pronouns, simplicity, reader engagement, active voice, brevity, rhetorical questions, as well as analogies and metaphors).
Make sure to use the keyword "${keyword}" in the article title.
Write article title to be up to 8 words length.
Each paragraph must be as long and as detailed as possible, minimum paragraph length must be 300 words.
Include at least 5 different paragraphs to fill 2000 words count, make sure that each paragraph is same length.
Write firstly an introduction section, which should be max 4 sentences long, never write ir longer than 4 sentences.
Article must have conclusion section with header at the end. 
All headings should be ## Heading level 2 headings.
The article keyword is "${keyword}". Use the provided keyword in article at least 4 times, so that it would be SEO optimized. Use it without quotes, add the keyword naturally.
Never include any horizontal lines in the markup.

${
  internalLinks
    ? `${getLinksInstruction('internal')}

    ` + internalLinks
    : ''
}

${
  externalLinks
    ? `${getLinksInstruction('external')}

    ` + externalLinks
    : ''
}

${(internalLinks || externalLinks) && `Important instructions for adding links:
Links should organically integrated into the article's context. 
All links must be clickable links and direct to the provided articles.
Use the text from provided links path as the anchor text for the links and don't use quotes.
Distribute the links throughout the article to avoid clustering them in one section specially don't add the links
in the end of the article as single section.
Don't add links in to headings.
Minimum count of links you should put is 3.
Don't add links in the last sentence of paragraph, try to put them in to middle of sections.
Don't use any quotes.
Integrate all provided links organically within the content in different sections.`}

`;
};

export const makeChatGptTableOfContentsQuery = (keyword) => {
  return `Now provide me table of contents for above article topic "${keyword}" in Markdown formatting.
  conclusion must be always at the end and don't include any greeting or note at end, only include first level headings, titles.`;
};

export const makeChatGptFaqQuery = (keyword) => {
  return `Now provide me faqs for above  article topic "${keyword}" in below json format.
  format: [{"question": "some string", "answer": "somestring"}]
  Generate 6 most important questions with detailed answers.
  Take care of escape characters so JSON don't break also 
  don't include any greeting or note at end, also avoid headings, titles etc.
  Remove text 'json'.
  Remove objects that don't have question and answer properties.`;
};

export const makeChatGptJsonLdFaqQuery = (faqs) => {
  return `Can you please provide me JSON-LD FAQ Page schema code for the below faqs:
   ${faqs}.
  Please include the script tags.
 Do not write HTML, Title, Or head tags.
 Do not write json.
Don't include any greeting or note at end.
Remove json text.`;
};

export const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
  str = str.toLowerCase(); // convert string to lowercase
  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
  return str;
};

export const { useQueryChatGptMutation } = openAiApis;
