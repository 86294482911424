import { pexelApis } from './api';

export const pexel = pexelApis.injectEndpoints({
  endpoints: (builder) => ({
    getPhotos: builder.mutation({
      query: (term) => `/search?query=${term}&orientation=landscape&per_page=1`,
    }),
  }),
});

export const { useGetPhotosMutation } = pexel;
