import React, { ReactNode } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Header from '../components/Header';
import SideMenu from '../components/SideMenu';

interface props {
    children: ReactNode
}

export default function MainLayout({ children }: props) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header />
      <SideMenu />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 2 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}