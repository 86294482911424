import { api } from './api';

export const domainsApis = api.injectEndpoints({
  endpoints: (builder) => ({
    getDomains: builder.query({
      query: () => ({
        url: 'domains?limit=100',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Domains', id: 'LIST' }],
    }),
    addDomain: builder.mutation({
      query: (body) => ({
        url: 'domains',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Domains', id: 'LIST' }],
    }),
  }),
});

const urlRegex = 
  /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})+$/;
export const isValidURL = (url) => urlRegex.test(url);

export const { useGetDomainsQuery, useAddDomainMutation } = domainsApis;
