import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Grid, TextField } from '@mui/material';
import { isValidURL, useAddDomainMutation } from '../../services/domains';
import { useNavigate } from 'react-router-dom';
import paths from '../../constants/paths';

const AddDomainPage = () => {
  const navigate = useNavigate();
  const [addDomain, { isLoading }] = useAddDomainMutation();
  const [inputs, setInputs] = React.useState({
    name: '',
    connectionUrl: '',
  });

  const handleAddDomain = () => {
    if (!inputs.name || !inputs.connectionUrl) return;
    if (!isValidURL(inputs.name)) {
      alert('Domain name must be valid URL.');
      return;
    }

    addDomain(inputs)
      .unwrap()
      .then(() => navigate(paths.domains))
      .catch((e) => alert(e?.data.message || e.message));
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" textAlign="center" mb={5}>
        Add Domain
      </Typography>

      <Grid
        container
        rowSpacing={5}
        columnSpacing={{ xs: 3, md: 10, lg: 20 }}
        pb={2}
      >
        <Grid item xs={6}>
          <TextField
            fullWidth
            placeholder="Domain name"
            helperText="* Enter domain name."
            error={!inputs.name}
            onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            placeholder="Connection string"
            helperText="* MongoDB connection string is required for DataBase connection."
            error={!inputs.connectionUrl}
            onChange={(e) =>
              setInputs({ ...inputs, connectionUrl: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            onClick={handleAddDomain}
            disabled={isLoading}
          >
            Add Domain
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddDomainPage;
