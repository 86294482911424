import React from 'react';
import Box from '@mui/material/Box';
import paths from '../../constants/paths';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useGetDomainsQuery } from '../../services/domains';

const DomainsPage = () => {
  const navigate = useNavigate();
  const { data } = useGetDomainsQuery();

  return (
    <Box>
      <Typography variant="h4" component="h1" textAlign="center" mb={1}>
        Domains
      </Typography>
      <Typography variant="h4" component="h5" textAlign="right">
        <Button variant="outlined" onClick={() => navigate(paths.addDomain)}>
          Add +
        </Button>
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontWeight: 700,
              }}
            >
              Domain
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 700,
              }}
            >
              Connection String
            </TableCell>

            {/** <TableCell
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            Action
                        </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.results.map((domain) => (
              <TableRow key={domain.name}>
                <TableCell>{domain.name}</TableCell>
                <TableCell>{domain.connectionUrl}</TableCell>
                {/**  <TableCell>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 1.5,
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    sx={{
                                        borderRadius: 10,
                                        minWidth: 110,
                                    }}
                                    size="small"
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        borderRadius: 10,
                                        minWidth: 110,
                                    }}
                                    size="small"
                                    color="error"
                                >
                                    Delete
                                </Button>
                            </Box>
                                </TableCell> */}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default DomainsPage;
