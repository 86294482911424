import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';

export default function Header() {
  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
            <Link
                href='/'
                variant="h6"
                component="div"
                color='background.paper'
            >
                Logo
            </Link>
        </Toolbar>
    </AppBar>
  );
}