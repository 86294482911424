import React from 'react';
import Box from '@mui/material/Box';
import { TextField, MenuItem } from '@mui/material';
import { useGetDomainsQuery } from '../../services/domains';

const SelectDomain = ({
  value,
  onChange,
  showError = true,
  helperText = 'Select domain',
}) => {
  const { data: domains } = useGetDomainsQuery();

  return (
    <Box>
      <TextField
        select
        fullWidth
        helperText={helperText}
        value={value || 0}
        error={showError && !value}
        onChange={onChange}
      >
        <MenuItem value={0}>Select domain...</MenuItem>
        {domains?.results.map((domain) => (
          <MenuItem value={domain.id} key={domain.id}>
            {domain.name}
          </MenuItem>
        ))}
        {!domains?.results?.length && (
          <MenuItem value={0}>No domain(s)</MenuItem>
        )}
      </TextField>
    </Box>
  );
};

export default SelectDomain;
