/* eslint-disable quote-props */
import React, { FC, ReactNode } from 'react';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { LinkProps } from '@mui/material/Link';
import Box from '@mui/material/Box';
import { Link as RouterLink, LinkProps as RouterLinkProps, BrowserRouter } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';

interface Props {
  children: ReactNode;
}

const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
});

const Router: FC<{ children?: ReactNode }> = (props) => {
  const { children } = props;
  if (typeof window === 'undefined') return <StaticRouter location="/">{children}</StaticRouter>;

  return <BrowserRouter>{children}</BrowserRouter>;
};

const CustomThemeProvider: FC<Props> = ({ children }) => {
  let theme = createTheme({
    typography: {
      fontSize: 14,
      htmlFontSize: 18.3,
      fontFamily: ['sans-serif', 'BlinkMacSystemFont', '"Segoe UI"', '"Nunito"', 'Arial', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
      h1: {
        fontSize: '38px',
        fontWeight: 'normal',
        lineHeight: '46px',
      },
      h2: {
        fontSize: '30px',
        fontWeight: 'bold',
        lineHeight: '38px',
      },
      h3: {
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '32px',
      },
      h4: {
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '28px',
      },
      h5: {
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '22px',
      },
      h6: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '22px',
      },
      body1: {
        fontSize: '16px',
        lineHeight: '22px',
      },
      body2: {
        fontSize: '14px',
        lineHeight: '22px',
      },
      caption: {
        fontSize: '12px',
        lineHeight: '135%'
      },
      captionTitle: {
        fontSize: '8px',
        lineHeight: '18px',
      }
    },
    palette: {
      primary: {
        light: '#737bd3',
        main: '#414bb2',
        dark: '#353d91',
        contrastText: '#fff',
      },
      secondary: {
        main: '#A3A6A8',
        light: '#A3A6A8',
        contrastText: '#000000',
      },
      warning: {
        main: '#F57953',
        contrastText: '#fff',
        light: '#F57953',
      },
      success: {
        light: '#44bc7b33',
        main: '#4FCC87',
        contrastText: '#fff',
      },
      error: {
        main: '#D85442',
        light: '#D85442',
        contrastText: '#fff',
      },
      info: {
        main: '#4D9AE4',
        light: '#29D2FC',
        contrastText: '#fff',
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
        styleOverrides: {
          root: {
            textDecoration: 'none',
            cursor: 'pointer'
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
  } as ThemeOptions);

  theme = createTheme(theme, {
    typography: {

    },
    components: {
      MuiButton: {
          styleOverrides: {
              root: {
                  borderRadius: 2,
                  fontSize: theme.typography.fontSize,
                  paddingLeft: 16,
                  paddingRight: 16,
                  boxShadow: 'none',
                  textTransform: 'none',
                  '&:hover': {
                      boxShadow: 'none',
                  }
              },
              sizeLarge: {
                  paddingTop: 12,
                  paddingBottom: 12,
              },
              sizeMedium: {
                  paddingTop: 8,
                  paddingBottom: 8,
              },
              sizeSmall: {
                  paddingTop: 4,
                  paddingBottom: 4,
              },
              outlined : {
                '&[active="true"]': {
                  backgroundColor: '#F3F9FD',
                  borderColor: theme.palette.primary.main,
                  boxShadow: '0px 0px 0px 2px rgba(12, 140, 233, 0.08)',
                },
                borderWidth: 2,
                '&:hover': {
                  borderWidth: 2,
                }
              }
          },
      },
      MuiLink: {
          styleOverrides: {
              root: {
                  textDecoration: 'none',
              },
          },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            border: '1px solid #F5F6F7',
            boxShadow: '0px 0px 128px rgba(0, 0, 0, 0.02)',
            borderRadius: 6,
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            '& .MuiPaper-root': {
              paddingLeft: 24,
              paddingRight: 24,
              borderRadius: 0,
              border: 0,
              boxShadow: '0px 0px 64px rgba(0, 0, 0, 0.04)',
              boxSizing: 'border-box'
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: theme.palette.secondary.main,
            borderRadius: 6,
          },
          input: {
            '&:not(textarea)': {
              padding: '21.5px 16px'
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            fontSize: '14px',
            lineHeight: '22px',
            '&[data-shrink=false]': {
              transform: 'translate(16px, 21px) scale(1)',
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            lineHeight: '120%',
            marginTop: 4
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          '& *': {
            boxSizing: 'border-box'
          }
        }}
      >
        <Router>{children}</Router>
      </Box>
    </ThemeProvider>
  );
};
export default CustomThemeProvider;
